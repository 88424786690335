import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { EmailConfirmation, EmailConfirmed } from './components/email-confirmation';
import { Newsletter } from './components/newsletter';
function App() {
  return (
    <div className="App container">
      <Router>
        <Routes>
          <Route path="/subscription-confirmed" Component={EmailConfirmed} />
          <Route path="/verify-email" Component={EmailConfirmation} />
          <Route path="/" Component={Newsletter} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
