import React, { useState } from "react";

export const Message = ({message}) => {
    const [dismiss, setDismiss] = useState(false);
    return <div
        style={{
            position: 'fixed',
            bottom: "20px",
            left: "20px",
            maxWidth: "200px",
            padding: "8px 12px",
            color: "white",
            backgroundColor: message.error === "error" ? "red" : "green",
            display: dismiss ? "none" : "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px"
        }}
    >
        {message}
        <button type="button" style={{
            color: "white",
            border: "none",
            background: "transparent"
        }} onClick={() => setDismiss(true)}>x</button>
    </div>
}