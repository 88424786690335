import React from "react";
import { useNavigate } from "react-router-dom";

export const EmailConfirmed = () => {
    const navigate = useNavigate();

    const styles = {
        button: {
            color: "#333",
            backgroundColor: "#F7D08A",
            border: "none",
            padding: "8px 16px",
            borderRadius: "4px",
            "&:hover": {
                backgroundColor: "#F7D099",
                cursor: "pointer"
            }
        }
    }
    return (
        <div>
            <h1>You are all set!</h1>
            <p>Be on the lookout for news regarding our exciting products</p>
            <button type="button" style={styles.button} onClick={() => navigate("/")}>Return Home</button>
        </div>
    )
}