import axios from 'axios';
import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Message } from '../message';

export const EmailConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState<any>({});
    const queryParams = new URLSearchParams(location.search);
    const contactId = queryParams.get('contactID');

    const handleSubscription = async() => {
        const subscriber = await axios.get(
            `https://api.useplunk.com/v1/contacts/${contactId}`, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_PLUNK_SECRET_API_KEY}`
                },
            }
        );
        const plunkResponse = await axios.post(
            'https://api.useplunk.com/v1/contacts/subscribe', 
            { id: contactId },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_PLUNK_SECRET_API_KEY}`
                },
            } 
        )

        if (plunkResponse.status === 200) {
            setMessage({ type: "success", message: 'Your subscription has been confirmed.' });
            
            const plunkSubscribedResponse = await axios.post(
                'https://api.useplunk.com/v1/track', 
                { 
                    event: "contact_subscribed", 
                    email: subscriber.data.email,
                    subscribed: true
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_PLUNK_SECRET_API_KEY}`
                    },
                } 
            )

            if(plunkSubscribedResponse.status === 200) {
                navigate("/subscription-confirmed");
            } else {
                setMessage({type: "error", message: 'Failed to confirm subscription. Please try again later.' });
            }
        } else {
            setMessage({type: "error", message: 'Failed to confirm subscription. Please try again later.' });
        }
        
    }

    const styles = {
        button: {
            color: "#333",
            backgroundColor: "#F7D08A",
            border: "none",
            padding: "8px 16px",
            borderRadius: "4px",
            "&:hover": {
                backgroundColor: "#F7D099",
                cursor: "pointer"
            }
        }
    }
    return (
        <>
        {Object.entries(message).length > 0 && <Message {...message} />}
            <div className="email-confirmation">
                <h1>You are one step away from awesomeness!</h1>
                <p>We really appreciate your interest in our products. Please click the button below to confirm your subscription to our newsletter.</p>
                <button type="button" onClick={handleSubscription} style={styles.button}>Confirm Subscription</button>
            </div>
        </>
    )
}