import axios from 'axios';
import React, { useState } from "react";
import { Message } from '../message';
import "./newsletter.scss";

export const Newsletter = () => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState<any>({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const validate = () => {
        let tempErrors = {};

        const requiredFields = [ 
            {name: 'firstName', title: 'First Name'}, 
            {name: 'lastName', title: 'Last Name'},
            {name: 'email', title: 'Email Address'}
        ];

        requiredFields.forEach(field => {
            if (!formData[field.name] || formData[field.name].trim() === '') {
              tempErrors[field.name] = `${field.title} is required`;
            }
          });
        
        setErrors(tempErrors);
        
        // Return true if there are no errors
        return Object.keys(tempErrors).length === 0;
    };

    const sendFormData = async(sentData) => {
        try {
            // Send contact to SQL DB
            // await axios
            // .post('http://localhost:3002/api/subscriber/create', {sentData})
            // .then((res) => {
            // return res;
            // })
            // .catch((err) => {
            //     console.log(err);
            // });

            const plunkResponse = await axios.post(
                'https://api.useplunk.com/v1/track', 
                { 
                    event: "contact_unsubscribed", 
                    data: {
                        firstName: sentData.firstName,
                        lastName: sentData.lastName,
                        email: sentData.email
                    },
                    email: sentData.email,
                    subscribed: false
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_PLUNK_SECRET_API_KEY}`
                    },
                } 
            );

            if(plunkResponse.status === 200) {
                setMessage({type: "success", message: 'Double opt-in email sent successfully'});
                setFormData({});
            } else {
                setMessage({type: "error", message: 'Failed to send double opt-in email'});
            }

        } catch (err) {

            console.error("Error sending data to track endpoint: ", err);
            setMessage({ type: "error", message: "Error sending data to track endpoint. Please try again later."});
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            sendFormData({...formData, created_on: new Date().toISOString()});
        } else {
            setMessage({ type: "error", message: `Validation errors: ${errors}` });
            console.error('Validation errors:', errors);
        }
    }

    const renderField = (name, title) => (
        <div key={name} className="form-control-group">
            <label>{title}</label>
            <input 
                id={name} 
                type={name === "email" ? "email" : "text"}
                name={name}
                value={formData[name] || ""}
                onChange={handleInputChange}
            />
            {errors[name] && <span className='error-message'>{errors[name]}</span>}
        </div>
    );

    const fieldNames = [
        {name: 'firstName', title: 'First Name'}, 
        {name: 'lastName', title: 'Last Name'}, 
        {name: 'email', title: "Email Address" }
    ];

    const styles = {
        image: {
            width: "100%",
            height: "100%",
            borderRadius: "4px",
        }
    }

    return (
        <>
            {Object.entries(message).length > 0 && <Message {...message} />}
            <div className="newsletter-container">
                <div className="flex gap-1 align-items-center">
                    <div style={{display: "inline-block", width: "20px", height: "2px", backgroundColor: "#333"}}></div>
                    <span className="sub-title">Coming soon</span>
                </div>
                <h1>Get notified <br/> when we launch</h1>
                <form className="newsletter-form gap-2 flex-column" onSubmit={handleFormSubmit}>
                    <div className="form-group flex-row flex-1 justify-space-between gap-2 wrap">
                        {fieldNames.map( (fieldName) => renderField(fieldName.name, fieldName.title) )}
                    </div>
                    <div>
                        <button 
                            type='submit'
                            className="newsletter-submit"
                        >Join us</button>
                    </div>
                </form>
                <span className='no-spam'>*Don't worry we won't spam you :)</span>
            </div>
            <div className='newsletter-image-container'>
                <img className='newsletter-image' src='/web-design1.png' alt='' style={styles.image} />
            </div>
        </>
    )
}